import { PERMISSION } from 'constants/permission';
import { WeekdayNum } from 'hooks/service/mutations/usePostBookingPrivate';
import { object, string } from 'yup';

import { BookingSelectMemberFormType, BookingStatusType } from './types';

export const BOOKING_FORM_COMMON_VALIDATE = {
  title: string().max(200, '수업명은 200자 이내로 입력해 주세요.'),
  description: string().max(200, '수업소개는 200자 이내로 입력해 주세요.'),
};

export const BOOKING_FORM_VALIDATE = object().shape(BOOKING_FORM_COMMON_VALIDATE);

export const BOOKING_COMMON_FORM_TEXT = {
  staff: {
    placeholder: '담당강사',
    drawer: {
      title: '담당강사 선택',
    },
  },
  repeat: {
    dialog: {
      positiveText: '계속',
      negativeText: '취소',
      description: `요일 변경 시,\n회원의 예약은 취소되고 횟수는 복구됩니다.\n계속 하시겠습니까?`,
    },
  },
  room: {
    noSetting: '룸 설정 안함',
    placeholder: '룸 설정',
    drawer: {
      title: '룸 설정',
    },
    dialog: {
      positiveText: '계속',
      negativeText: '취소',
      description: {
        notUsable: '사용 가능한 룸이 없습니다.',
        notSelectedConfirm: `룸이 설정되지 않았습니다. \n계속 하시겠습니까?`,
      },
    },
    notSelected: {
      value: 'room-id-notSelected',
      label: '룸 선택 안함',
    },
    resetType: {
      date: '날짜가',
      time: '시간이',
      repeat: '요일이',
    },
  },
  reset: {
    changeTarget: {
      date: '날짜가',
      time: '시간이',
      repeat: '요일이',
    },
    resetTarget: {
      room: '설정된 룸',
      member: '수강회원',
      both: '룸, 수강회원',
    },
  },
  title: {
    placeholder: '수업명',
  },
  dialogBeforeLeave: `예약정보가 저장되지 않았습니다. \n페이지를 나가시겠습니까?`,
  successMessage: {
    create: '수업이 등록되었습니다.',
    update: '수업이 수정되었습니다.',
  },
  footerButton: {
    create: '등록',
    update: '수정',
    copy: '등록',
    updateAll: '수정',
  },
  loadingTitle: '수업정보 수정중',
  refresh: '\n페이지를 새로고침 하세요.',
};

/**
 * 그룹 또는 프라이빗 수업 생성 시,
 * 다른 스태프 수업 등록 및 수정이 가능한 지 확인
 * - 가능: 강사 선택 필드 클릭 가능(클릭 시, 강사 리스트 drawer 노출)
 * - 불가능: 강사 선택 필드 클릭 불가능
 */
export const BOOKING_SELECT_STAFF_PERMISSION = {
  mine: {
    create: {
      group: PERMISSION.lecture.mine.create.group,
      private: PERMISSION.lecture.mine.create.private,
    },
    update: {
      group: PERMISSION.lecture.mine.editLecture.group,
      private: PERMISSION.lecture.mine.editLecture.private,
    },
  },
  others: {
    create: {
      group: PERMISSION.lecture.others.create.group,
      private: PERMISSION.lecture.others.create.private,
    },
    update: {
      group: PERMISSION.lecture.others.editLecture.group,
      private: PERMISSION.lecture.others.editLecture.private,
    },
  },
};

export const REPEAT_SETTING_CHECK_RADIO_DATA = [
  { id: 'everyMonday', label: '매주 월요일', value: 'everyMonday' },
  { id: 'weekday', label: '주중 매일 (월~금)', value: 'weekday' },
];

export const REPEAT_CUSTOM_SETTING_WEEKDAY = [
  { id: 'mon', label: '월요일', value: '월' },
  { id: 'tue', label: '화요일', value: '화' },
  { id: 'wed', label: '수요일', value: '수' },
  { id: 'thu', label: '목요일', value: '목' },
  { id: 'fri', label: '금요일', value: '금' },
  { id: 'sat', label: '토요일', value: '토' },
  { id: 'sun', label: '일요일', value: '일' },
];

export const CONVERT_WEEKDAY_TO_NUM: { [key: string]: WeekdayNum } = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};

export const CONVERT_NUM_TO_WEEKDAY: { [key: number]: string } = {
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat',
};

export const BOOKING_FORM_DEFAULT_HEADER_TITLE_TEXT = {
  private: {
    create: '프라이빗 수업 예약',
    update: '프라이빗 수업 수정',
    updateAll: '프라이빗 수업 수정',
    copy: '프라이빗 수업 예약',
  } as { [key: string]: string },
  group: {
    create: '그룹 수업 등록',
    update: '그룹 수업 수정',
    updateAll: '그룹 수업 수정',
    copy: '그룹 수업 등록',
  } as { [key: string]: string },
};

export const BOOKING_FORM_DETAIL_HEADER_TITLE_TEXT = {
  repeat: '반복 설정',
  custom: '맞춤 설정',
  selectMember: '수강회원 선택',
  bookingCancelTime: '예약/취소 가능 시간',
  checkInTime: '체크인 가능 시간',
  otherDetailTime: '당일예약변경/폐강/대기예약',
};

export const BOOKING_STATUS_LABEL = {
  booked: { text: '예약', color: 'primary' },
  booking_confirmed: { text: '예약확정', color: 'primary' },
  booking_waiting: { text: '대기', color: 'gray2' },
  attendance: { text: '출석', color: 'blueViolet1' },
  absence: { text: '결석', color: 'secondary3' },
  noshow: { text: '노쇼', color: 'secondary3' },
  cancel: { text: '예약취소', color: 'primary' },
  booking_pending: { text: '예약중', color: 'primary' },
};

export const BOOKING_STATUS: Array<BookingStatusType> = [
  { label: '예약', value: 'booked' },
  { label: '예약취소', value: 'cancel' },
  { label: '예약확정', value: 'booking_confirmed' },
  { label: '결석', value: 'absence' },
  { label: '노쇼', value: 'noshow' },
  { label: '출석', value: 'attendance' },
  { label: '예약취소', value: 'waiting_cancel' },
];

export const BOOKING_DETAIL_CANCEL_DIALOG_TEXT = {
  cancel: {
    description: ` 님의\n 예약을 취소하시겠습니까?`,
    positiveText: '확인',
    negativeText: '취소',
    successMessage: '예약이 취소되었습니다.',
  },
  waitingCancel: {
    description: ` 님의\n 예약대기를 취소하시겠습니까?`,
  },
  delete: {
    description: ` 님의 예약은 취소되고\n수업은 삭제됩니다.`,
    positiveText: '확인',
    negativeText: '취소',
    successMessage: '수업이 삭제되었습니다.',
  },
};

export const BOOKING_RULE_TYPE_DATA = [
  { id: 'R', label: '수업시간 기준 - 마감시간까지', value: 'R' },
  { id: 'RR', label: '수업시간 기준 - 시작시간부터 마감시간까지', value: 'RR' },
  { id: 'A', label: '수업일 기준 - 마감시간까지', value: 'A' },
  { id: 'AR', label: '수업일 기준 - 시작시간부터 마감시간까지', value: 'AR' },
];

export const BOOKING_AVAILABLE_TIME_SETTING_TITLE: { [key: string]: string } = {
  booking: '예약 가능 시간',
  cancel: '예약취소 가능 시간',
};

export const BOOKING_AVAILABLE_TIME_SETTING_TEXT = {
  booking: '까지 예약 가능합니다.',
  cancel: '까지 예약취소 가능합니다.',
  checkIn: '까지 체크인 가능합니다.',
  dailyChange: '까지',
  waiting: '까지 자동 예약됩니다.',
  autoClose: '에 폐강됩니다.',
};

export const BOOKING_DETAIL_DELETE_TEXT = {
  dialog: {
    title: '수업을 삭제하시겠습니까?',
    titleDeleteAll: '모든 수업 삭제',
    negativeText: '취소',
    positiveText: '삭제',
    description: {
      delete: `수업을 삭제할 경우\n회원들의 예약은 취소됩니다.`,
      deleteAfterDate: `수업을 삭제할 경우\n회원들의 예약은 취소됩니다.`,
      deleteBeforeLecture: `지난 수업을 삭제할 경우\n회원의 예약은 취소되고 횟수는 복구됩니다.`,
      deleteAll: `지난 수업을 포함하여\n등록된 모든 수업을 삭제합니다.\n(삭제된 수업은 복구할 수 없습니다.)`,
    },
  },
  successMessage: '수업이 삭제되었습니다.',
  loadingTitle: '수업 삭제중',
};

export const BOOKING_DETAIL_OPEN_CLOSE_TEXT = {
  open: {
    dialog: {
      title: '예약마감을 해제하시겠습니까?',
      negativeText: '취소',
      positiveText: '마감해제',
      description: `예약마감을 해제할 경우,\n회원은 앱에서 다시 예약할 수 있습니다.`,
    },
    successMessage: '예약마감이 해제되었습니다.',
  },
  close: {
    dialog: {
      title: '예약을 마감하시겠습니까?',
      negativeText: '취소',
      positiveText: '예약마감',
      description: `예약을 마감할 경우,\n회원은 앱으로 예약할 수 없습니다.`,
    },
    successMessage: '예약이 마감되었습니다.',
  },
};

export const SELECT_MEMBER_HEADER_TITLE_TEXT = {
  booking: '회원 변경',
  exceed: '초과예약 선택',
  waiting: '예약대기 선택',
  waitingUnLimit: '예약대기 선택',
  waitingLimit: '예약대기 추가',
} as { [key in BookingSelectMemberFormType['type']]: string };

export const LIMIT_VALIDATION_DATA = {
  daily: '일일 예약가능 횟수',
  weekly: '주간 예약가능 횟수',
  monthly: '월간 예약가능 횟수',
} as { [key: string]: string };

export const SELECT_MEMBER_FORM_TEXT = {
  reservedLabel: {
    booking: '예약인원',
    exceed: '예약인원',
    waiting: '예약대기 인원',
    waitingUnLimit: '예약대기 인원',
    waitingLimit: '예약대기 인원',
  },
  footerButton: {
    booking: '추가',
    exceed: '선택',
    waiting: '선택',
    waitingUnLimit: '선택',
    waitingLimit: '추가',
  },
  successMessage: {
    booking: '예약인원이 추가되었습니다.',
    exceed: '예약인원이 추가되었습니다.',
    waiting: '예약대기 회원이 추가되었습니다.',
    waitingUnLimit: '예약대기 회원이 추가되었습니다.',
    waitingLimit: '예약대기 회원이 추가되었습니다.',
  } as { [key in BookingSelectMemberFormType['type']]: string },
  dialog: {
    error: {
      exceed: '예약 가능한 인원이 초과되었습니다.',
    },
  },
};

export const STAFF_ERROR_KEYS = ['10901', '10902', '10904']; // 강사 중복
export const ROOM_ERROR_KEYS = ['13001']; // 룸 중복

/**
 * 10020: 예약횟수 부족, 10019: 기간이 만료된 수강권 포함, 10621: 유효하지 않은 수강권 (사용불가, 양도된 수강권 등)
 * 10012: 정지중인 수강권, 10017: 수업날짜가 만료일 초과, 10312: 삭제된 수강권
 * 10006: 예약인원 초과, 10103: 예약대기 인원 초과
 */
export const NO_ADD_ERROR_MESSAGE_KEYS = [10012, 10017]; // 프라이빗 수업 검증에서 추가 문구 없어야 하는 코드
export const TICKET_INVALID_KEYS = [...NO_ADD_ERROR_MESSAGE_KEYS, 10019, 10020, 10621, 10312];
export const REFRESH_KEYS = [...TICKET_INVALID_KEYS, 10006, 10103];

export const BOOKING_ERROR_DATA: { [key: string]: string } = {
  10503: '휴일 설정',
  10004: '예약불가 수업',
  10012: '정지 기간',
  15002: '예약 실패',
  15004: '예약 실패',
  10000: '예약횟수 부족',
  10020: '예약횟수 부족',
  10016: '일정',
  10901: '강사',
  10902: '강사',
  10904: '강사',
  13001: '룸',
  10506: '예약횟수 초과',
  10006: '정원 초과',
  10003: '예약된 수업',
  10101: '예약대기 불가',
  10001: '예약대기 초과',
  10102: '예약대기 불가',
  10103: '대기횟수 초과',
};
